<template>
  <div class="container-top" style="padding-bottom:0">
    <div class="pc">
      <div class="section-1">
        <div class="container" style="display: block">
          <div class="flex-between">
            <div>
              <div class="main header-page-title-bold margin-bottom-16"><span class="weight-400 header-page-title-title">내
                  아이디어,</span>
                <br>외주개발 없이<br>바로 만들어 볼 수 있는 툴 없을까?
              </div>
              <div class="flex-align">
                <div class="body2-bold primary" style="margin-right:16px">플랫폼파이 1.0 런칭</div>
                <div class="body2 sub2">웹/앱 플랫폼 아이디어 구현을 위한, 가장 혁신적인 솔루션</div>
              </div>
            </div>
            <img src="/static/img/home/img_think.svg" class="section1-img">
          </div>
          <div style="display:grid;grid-template-columns: repeat(3, 1fr);grid-column-gap: 28px;margin-top:48px">
            <div class="partner-box flex-center" style="margin-top:38px">
              <div>
                <div class="flex-align margin-bottom-8">
                  <img src="/static/img/home/partner_2.png" style="width:40px;height:40px;margin-right:12px">
                  <div class="body4-medium main">아이디어 뿜뿜! 직장인</div>
                </div>
                <div class="body4 sub2">일반 홈페이지, 쇼핑몰 빌더로는 원하는 서비스를 만들 수 없고...</div>
              </div>
            </div>
            <div class="partner-box flex-center">
              <div>
                <div class="flex-align margin-bottom-8">
                  <img src="/static/img/home/partner_3.png" style="width:40px;height:40px;margin-right:12px">
                  <div class="body4-medium main">돈이 없지 열정이 없냐 대학생</div>
                </div>
                <div class="body4 sub2">MVP(최소가치제품) 개발에만 평균 3000만원 이상 필요하다는데</div>
              </div>
            </div>
            <div class="partner-box flex-center" style="margin-top:24px">
              <div>
                <div class="flex-align margin-bottom-8">
                  <img src="/static/img/home/partner_1.png" style="width:40px;height:40px;margin-right:12px">
                  <div class="body4-medium main">아직 목마른 사업가</div>
                </div>
                <div class="body4 sub2">아직 아이디어 검증이 안되어 비용투자 하기엔 확실치 않은데</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-2">
        <div class="container">
          <div class="col-4">
            <div class="h3 main weight-700"><span class="weight-400">1개월 단위로 구매하고</span><br>웹/앱 플랫폼 아이디어를<br>검증하세요!</div>
            <div class="body2 sub2 margin-top-16">MVP 런칭까지의 과정에<br>정말로 개발자가 필요하지 않습니다!<br>웹/앱을 쉽고 빠르게 만들어보세요.</div>
            <div class="flex" style="margin-top:40px">

              <button class="button-home button-primary" style="width:220px" @click="routerPush('/theme_market')">
                <div class="body2-bold text-center">무료체험 바로가기</div>
              </button>
            </div>
          </div>
          <div class="col-8 flex-end" style="padding-left:80px">
            <video width="100%" :autoplay="true" :loop="true" muted playsinline controls="true" controlsList="nodownload"
              oncontextmenu="return false">
              <!--<source src="/static/img/home/launchpack_guide.mp4" type="video/mp4">-->
              <source src="https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/service_main.mp4"
                type="video/mp4">
            </video>
          </div>
        </div>
      </div>
      <div class="section-3">
        <div class="container">
          <div style="width:100%">
            <div class="section-title-bold main margin-top-16"><span class="section-title">플랫폼파이가 탄생한 이유 <span
                  class="primary">첫번째.</span></span><br>쇼핑몰, 홈페이지가 아니라 웹서비스, 플랫폼서비스를<br>만들 수 있게 하기 위해서예요.</div>
            <div class="body2 sub2" style="margin:24px 0 60px">기존 쇼핑몰, 홈페이지 솔루션으로 만들 수 없었던<br>웹 서비스, 플랫폼 서비스를 누구나 만들 수 있게
              하였습니다!</div>
            <div class="sec3-box margin-bottom-20 flex-between">
              <div>
                <div class="sec3-txt1 body2-medium margin-bottom-16">기존 쇼핑몰, 홈페이지 솔루션으로 만드는 서비스</div>
                <div class="body1 sub3 text-center">하나의 공급자만 존재하는 홈페이지, 쇼핑몰 사이트</div>
              </div>
              <div style="width:530px">
                <img src="/static/img/home/sec3_img1.png">
              </div>
            </div>
            <div class="sec3-box2 flex-between">
              <div style="margin-left:36px">
                <div class="flex-justify">
                  <div class="sec3-txt2 body2-bold margin-bottom-16">플랫폼파이로 만드는 서비스</div>
                </div>
                <div class="body1 sub text-center">다수의 공급자와 수요자가 참여하는<br>웹/앱 플랫폼 서비스</div>
              </div>
              <div style="width:540px">
                <img src="/static/img/home/sec3_img2.png">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-7" style="padding:100px 0">
        <div class="container" style="flex-direction: column">
          <div style="text-align: center" class="margin-bottom-40">
            <div class="subtitle2 main">그 외에도 다양한 주문 서비스가 가능합니다!</div>
          </div>
          <div class="grid" style="grid-template-columns: repeat(6, 1fr);grid-column-gap:20px;width:100%">
            <div class="position-relative" v-for="(item, idx) in extras" :key="'card-extra-' + idx"
              @click="clickExtra(item)">
              <div class="card-extra flex-center margin-bottom-8">
                <div>
                  <div class="flex-justify">
                    <div class="extras-icon-bg flex-center">
                      <img :src="`/static/img/home/${item.icon}.svg`">
                    </div>
                  </div>
                  <div style="margin-top:12px" class="text-center">
                    <div class="main size-15 weight-500 margin-bottom-4">
                      {{ item.title }}</div>
                  </div>
                </div>
              </div>
              <div class="body5 sub3 text-center" style="word-break:keep-all;padding:0 12px">{{ item.desc }}</div>
              <div class="body6-bold position-absolute sub-style"
                :class="[item.subTitle === 'Prime' ? 'sub-prime' : 'sub-ex']">{{ item.subTitle }}</div>
            </div>
          </div>
          <button class="button is-primary body2-bold margin-top-40" style="width:220px;height:56px"
            @click="clickInquiryCustom">맞춤제작 문의</button>
        </div>
      </div>

      <div class="section-4">
        <div class="container" style="align-items: flex-start">
          <div class="col-12 text-center">
            <div class="sub3 margin-bottom-12">아이디어 테스트 단계</div>
            <div class="h4 main"><span>1개월 단위로 플랫폼 서비스를 운영해보고 싶은 창업자를 위한 </span><span class="primary">플랫폼파이 <br> 무료
                체험해보세요!</span></div>
            <div class="body2 sub3 margin-top-12">블럭 단위로 디자인 편집을 할 수 있는 빌더가 포함되어 있어<br />간단히 내 아이디어에 맞게 설정하고 런칭할 수 있어요.
            </div>
          </div>

          <div class="grid-product-wrap margin-top-80">
            <card-product v-for="theme in themes"
                          :key="`theme-${theme.id}`" :card="theme"></card-product>
          </div>
          <div class="col-12 margin-top-80">
            <a href="https://answer.moaform.com/answers/MjKXK5" target="_blank" style="color:#ff6600">
            </a>
          </div>
        </div>
        <div class="flex-justify margin-top-40"
          style="background: linear-gradient(180deg, rgba(250, 250, 250, 0) 36.46%, #efefef 100%)">
          <div class="platformfy-info-container">
            <div class="img-slider">
              <div class="img">
                <img v-for="idx in 30" :key="`img-slider-${idx}`" src="/static/img/home/info_img_full_pc.png">
              </div>
            </div>
            <div>
              <div class="subtitle3 main" style="line-height: 36px">이미 많은 고객분들께서 플랫폼파이로</div>
              <div class="h5 main" style="line-height: 36px">초기 아이디어를 검증하고,<br />투자를 유치하고 계십니다!</div>
              <div class="margin-top-40">
                <a href="https://platformfy.com/theme_market" target="_blank">
                  <button class="button is-main body2-medium flex-align"
                    style="background-color: transparent; gap:0 10px;width:290px">
                    테마 더보기 <svg-icon icon="u_arrow-up-right" :width="18" :height="18" color="main"></svg-icon>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div class="section-5">
        <div class="container" style="align-items: flex-start">
          <div class="col-12">
            <div class="section-title-bold main margin-top-16"><span class="section-title">플랫폼파이가 탄생한 이유 <span
                  class="primary">두번째.</span></span><br>앱 형태만 갖췄거나, 프로토타입 수준이 아닌<br>
              제대로 된 비즈니스를 운영할 수 있는 서비스를 제작하기 위해서예요.</div>

            <div class="body2 sub2" style="margin:24px 0 24px">국내외 노코드 툴을 찾아 원하는 앱 서비스를 만들었다 하더라도,<br>
              실제 비즈니스가 가능한 앱은 제작할 수 없었습니다.</div>
            <div class="body2 sub2" style="margin-bottom:40px">서비스를 운영하기 위해서는, 수수료 정산이나 회원 관리 등이 가능한 관리자 사이트가 필요하기
              때문이죠.<br>
              플랫폼파이는 서비스를 운영하기 위해 필요한 기능들이 기본적인 수준부터, 비즈니스 수준까지 준비되어 있습니다.</div>
          </div>
        </div>
      </div>

      <div class="section-3">
        <div class="container">
          <div class="col-12">
            <div class="section-title-bold main"><span class="section-title">플랫폼파이로 만들면</span><br>서비스 뿐만 아니라 관리자 사이트까지
              제공되어 비즈니스 운영이 가능합니다.</div>
          </div>

          <div class="col-12 flex-between" style="margin-top:60px">
            <div class="col-7" style="padding-right:60px">
              <img src="/static/img/home/notebook.png" style="margin-top:26px">
            </div>
            <div class="grid col-5"
              style="grid-template-columns: repeat(3,1fr);grid-column-gap:12px;grid-row-gap:12px;filter:drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04))">
              <div v-for="(item, idx) in funcList" :key="'func-' + idx">
                <div class="flex-align func-item">
                  <img :src="`/static/img/home/${item.icon}.svg`" :alt="item.title" style="margin-right:8px">
                  <div class="body4 sub2" style="white-space:pre-line">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-9">
        <div class="container text-center" style="display: block">
          <div class="subtitle2 main margin-bottom-16">플랫폼파이 솔루션은 일반적으로 많이 사용되는<br>기술스펙으로 구성되어 있습니다.</div>
          <div class="body2 sub3 margin-bottom-40">언제든 고도화가 가능하고 투자유치나 사업규모 확장으로 독립이 필요한 경우 별도 계약을 통해<br>
            프론트엔드 소스코드 공유 및 백엔드 API 전송이 가능합니다.</div>
          <img src="/static/img/home/img_info_source.png">

        </div>
      </div>

      <div class="section-8">
        <div class="container">
          <div class="col-12 subtitle2 main text-center margin-bottom-40">전체 플랫폼 팩의 기본 화면과 기능들이예요.</div>
          <div style="width:1152px">
            <slick ref="slick" :options="slickOptionsFunc">
              <img v-for="(i, idx) in Array(39).fill().map((i, c) => (c % 13) + 1)" :key="'platformfy-func-' + idx"
                :src="`/static/img/home/img_func${i}.png`" style="padding:0 12px">
            </slick>
          </div>
        </div>
      </div>

      <div class="section-8">
        <div class="container" style="flex-direction: column">
          <div style="text-align: center" class="margin-bottom-40">
            <div class="subtitle2 main">만약 필요한 기능이나 화면이 있으면 어떻게 하죠?</div>
            <div class="body1 sub3 margin-bottom-24" style="margin-top: 8px">앱마켓에서 팩, 플러그인 추가, 템플릿 무제한 이용권 구매, 디자인 요청이
              가능해요.</div>
            <div class="flex-justify margin-bottom-40">
              <div class="flex-align unselect" @click="clickPush('/market/list')">
                <div class="body2-medium primary" style="margin-right:12px">자세히 보기</div>
                <img src="/static/img/home/draw_arrow-right.svg" class="svg-primary">
              </div>
            </div>
            <img src="/static/img/home/app-market.png">
          </div>
          <div style="margin:120px 0" class="col-12 flex-between">
            <div style="width:470px">
              <div class="subtitle3 main margin-bottom-16">원하는 디자인이나 기능이 없다면<br>직접 요청하세요!</div>
              <div class="body2 sub2">내 서비스를 편집하는 빌더에서 간단하게 직접 디자인을 요청하세요!<br>
                일일이 프리랜서와 디자이너에게 컨택을 하고, 명세서를 전달하고, 견적을 받고, 검수할 필요 없이 플랫폼파이에서 올인원으로 필요한 리소스를 해결해 드립니다.</div>
            </div>
            <img src="/static/img/home/request-frame.png" style="height:460px">
          </div>
        </div>
      </div>

      <!-- <div class="bg-prime">
        <div class="container position-relative">
          <div>
            <div class="size-32 primary margin-bottom-8">사업이 확장됨에 따라</div>
            <div class="h3 primary margin-bottom-8">Prime 서비스로 고도화가 가능합니다.</div>
            <div class="body2 gray margin-bottom-24">비즈니스 스케일업 수준에 맞춘 제작이 필요하기 시작할 때 Prime 서비스로 계속해서 서비스 고도화가 가능합니다.</div>
            <div class="body2-medium primary unselect" @click="routerPush('/prime')">자세히보기<img src="/static/img/home/draw_arrow-right.svg"
                                                        class="svg-primary" style="margin-left:12px"
                                                        ></div>
          </div>
          <img src="/static/img/home/ic-prime-banner.svg"
              style="position: absolute;right:0;top:0">
        </div>
      </div> -->

      <div class="section-4">
        <div class="container">
          <div style="width:100%">
            <div class="section-title-bold main margin-bottom-32"><span class="section-title">내 서비스 런칭 준비를
                마치셨다면</span><br>앱 포팅 서비스로<br>내 앱을 구글플레이, 앱스토어에 올려보세요!</div>
            <div class="body1 sub2 margin-bottom-32">앱 포팅 및 앱 마켓 등록 대행부터 GA(Google Analytics) 등록 등<br>
              비즈니스 운영에 필요한 부가서비스들을 활용해 내 서비스를 본격적으로 사업화할 수 있습니다.</div>
            <div class="margin-bottom-56">
              <div class="flex-align unselect" @click="clickPush('/market/list?id=49')">
                <div class="body2-medium primary" style="margin-right:12px">자세히 보기</div>
                <img src="/static/img/home/draw_arrow-right.svg" class="svg-primary">
              </div>
            </div>
            <div style="display: grid;grid-template-columns: repeat(4, 1fr);grid-column-gap: 24px;grid-row-gap:32px">
              <div v-for="(item, idx) in serviceList" :key="'serv-' + idx">
                <img :src="`/static/img/home/${item.img}.svg`">
                <div style="margin-top:12px" class="body2 main">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-3">
        <div class="container">
          <div>
            <div class="section-title-bold margin-bottom-60 main"><span class="section-title">이미 많은 고객분들께서
                플랫폼파이로</span><br>초기 아이디어를 검증하고, 투자를 유치하고 계십니다!</div>
            <div class="flex-align">
              <svg-icon icon="fi_check-circle" color="primary" :width="24" :height="24"
                style="margin-right:8px"></svg-icon>
              <div class="subtitle4 primary">Platformfy로 제작</div>
            </div>
            <div style="width:1152px;margin-top:60px">
              <slick ref="slick" :options="slickOptions">
                <img v-for="(i, idx) in Array(15).fill().map((i, c) => (c % 5) + 1)" :key="'platformfy-ex-' + idx"
                  :src="`/static/img/home/img_platformfy_ex${i}.png`">
              </slick>
            </div>
          </div>
        </div>
      </div>

      <div class="section-11">
        <img class="position-absolute" src="/static/img/home/ic_ellipse_91.svg"
          style="left: 70%;transform: translateX(-50%);">
        <div class="container flex-center" style="gap:140px;z-index:1">
          <div>
            <img class="margin-bottom-20" style="width:132px;height:32px" src="/static/img/img_logo_launchpack.png"
              alt="런치팩 로고">
            <div class="size-28 main margin-bottom-4">원하시는 테마가 없으신가요?</div>
            <div class="size-36 weight-700 main margin-bottom-40">부분 수정할 수 있는 스킨과<br>맞춤제작이 가능한 제작스토어가 있습니다</div>
            <!--<a href="https://pfa.launchpack.co.kr/home" target="_blank">-->
            <a href="https://launchpack.co.kr/home" target="_blank">
              <button class="button is-primary body2-medium" style="width:220px;height:48px">제작스토어 가기</button>
            </a>
          </div>
          <img src="/static/img/home/img_skin_market.png" alt="" style="width:508px;height:449px;margin-right:-118px">
        </div>
      </div>
    </div>

    <!-- mobile -->
    <div class="mobile tablet">
      <div class="section-1-mo">
        <div class="position-absolute flex"
          style="width:100%;height:100%;top:0;left:0;padding:100px 0 20px;flex-direction: column">
          <div class="text-center body4-bold primary margin-bottom-20">플랫폼파이 1.0 런칭</div>
          <div class="mo-pagetitle-bold main margin-bottom-20 text-center"><span class="mo-pagetitle">내
              아이디어,</span><br>외주개발 없이<br>바로 만들어 볼 수 있는<br>툴 없을까?</div>
          <div class="margin-bottom-40 body3 sub2 text-center">웹/앱 플랫폼 아이디어 구현을 위한,<br>가장 혁신적인 솔루션</div>
          <div class="flex-justify margin-bottom-12">
            <div class="partner-box flex-center" style="width:300px">
              <div>
                <div class="flex-align margin-bottom-8">
                  <img src="/static/img/home/partner_2.png" style="width:40px;height:40px;margin-right:12px">
                  <div class="body4-medium main">아이디어 뿜뿜! 직장인</div>
                </div>
                <div class="body4 sub2">일반 홈페이지, 쇼핑몰 빌더로는 원하는 서비스를 만들 수 없고...</div>
              </div>
            </div>
          </div>
          <div class="flex-justify margin-bottom-12">
            <div class="partner-box flex-center" style="width:300px">
              <div>
                <div class="flex-align margin-bottom-8">
                  <img src="/static/img/home/partner_3.png" style="width:40px;height:40px;margin-right:12px">
                  <div class="body4-medium main">돈이 없지 열정이 없냐 대학생</div>
                </div>
                <div class="body4 sub2">MVP(최소가치제품) 개발에만 평균 3000만원 이상 필요하다는데</div>
              </div>
            </div>
          </div>
          <div class="flex-justify">
            <div class="partner-box flex-center" style="width:300px">
              <div>
                <div class="flex-align margin-bottom-8">
                  <img src="/static/img/home/partner_1.png" style="width:40px;height:40px;margin-right:12px">
                  <div class="body4-medium main">아직 목마른 사업가</div>
                </div>
                <div class="body4 sub2">아직 아이디어 검증이 안되어 비용투자 하기엔 확실치 않은데</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-2">
        <div class="container">
          <div class="col-12 margin-bottom-12 mo-section-title-bold main"><span class="mo-section-title">이제 1개월 단위로
              구매하고</span><br>웹/앱 플랫폼 아이디어를<br>검증하세요!</div>
          <div class="col-12 body4 sub2 margin-bottom-32">MVP 런칭까지의 과정에 정말로 개발자가<br>필요하지 않습니다! 웹/앱을 쉽고 빠르게 만들어보세요.</div>
          <video width="100%" :autoplay="true" :loop="true" playsinline controls="true" muted style="margin-bottom:40px"
            controlsList="nodownload" oncontextmenu="return false">
            <source src="https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/service_main.mp4"
              type="video/mp4">
          </video>
          <div class="flex-justify col-12">
            <button class="button-home-mo button-primary" style="margin-right:6px" @click="routerPush('/theme_market')">
              <div class="flex-justify">
                <div class="flex-align">
                  <div class="body4-medium">무료체험 바로가기</div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="section-3">
        <div class="container">
          <div class="margin-bottom-12 mo-section-title-bold main"><span class="mo-section-title">플랫폼파이가 탄생한 이유 <span
                class="primary">첫번째.</span></span><br>쇼핑몰, 홈페이지가 아니라 웹서비스, 플랫폼서비스를 만들 수 있게 하기 위해서예요</div>
          <div class="body4 sub2 margin-bottom-32">기존 쇼핑몰, 홈페이지 솔루션으로 만들 수 없었던<br>웹 서비스, 플랫폼 서비스를 누구나 만들 수 있게 하였습니다!</div>
          <div class="sec3-box-mo margin-bottom-12">
            <div class="flex-justify text-center">
              <div class="sec3-txt1-mo body4-medium margin-bottom-8">기존 쇼핑몰, 솔루션으로 만드는 서비스</div>
            </div>
            <div class="body5-medium sub3 text-center margin-bottom-32">하나의 공급자만 존재하는 홈페이지, 쇼핑몰 사이트</div>
            <div class="flex-justify">
              <img src="/static/img/home/sec3_img1_mo.png">
            </div>
          </div>
          <div class="sec3-box2-mo">
            <div class="flex-justify">
              <div class="sec3-txt2-mo body4-medium margin-bottom-8 text-center">플랫폼파이로 만드는 서비스</div>
            </div>
            <div class="body5-medium sub text-center margin-bottom-24">다수의 공급자와 수요자가 참여하는<br>웹/앱 플랫폼 서비스</div>
            <div class="flex-justify">
              <img src="/static/img/home/sec3_img2_mo.png">
            </div>
          </div>
        </div>
      </div>
      <div class="section-5">
        <div class="container">
          <div class="margin-bottom-12 mo-section-title-bold main"><span class="mo-section-title">플랫폼파이가 탄생한 이유 <span
                class="primary">두번째.</span></span>
            <br>앱 형태만 갖췄거나, 프로토타입 수준이 아닌 제대로 된 비즈니스를 운영할 수 있는 서비스를 제작하기 위해서예요.
          </div>
          <div class="body4 sub2">국내외 노코드 툴을 찾아 원하는 앱 서비스를 만들었다 하더라도,
            실제 비즈니스가 가능한 앱은 제작할 수 없었습니다. <br> <br>
            서비스를 운영하기 위해서는, 수수료 정산이나 회원 관리 등이 가능한 관리자 사이트가 필요하기 때문이죠. <br>
            플랫폼파이는 서비스를 운영하기 위해 필요한 기능들이 기본적인 수준부터, 비즈니스 수준까지 준비되어 있습니다.

          </div>
        </div>
      </div>

      <div class="section-3">
        <div class="container">
          <div class="margin-bottom-40 main mo-section-title-bold"><span class="mo-section-title">플랫폼파이로 만들면</span><br>서비스
            뿐만 아니라<br>관리자 사이트까지 제공되어<br>비즈니스 운영이 가능합니다.</div>
          <div class="grid margin-bottom-48"
            style="grid-template-columns: repeat(2, 1fr);grid-row-gap:8px;width:100%;grid-column-gap:10px">
            <div v-for="(item, idx) in funcList" :key="'func-' + idx" class="func-item-mo flex-align">
              <img :src="`/static/img/home/${item.icon}.svg`" :alt="item.title" class="func-icon">
              <div class="body4 sub2" style="white-space:pre-line;margin-left:10px">{{ item.title }}</div>
            </div>
          </div>
          <div class="flex-justify col-12">
            <img src="/static/img/home/notebook.png">
          </div>
        </div>
      </div>

      <div class="section-4">
        <div class="container">
          <div class="margin-bottom-24 main mo-section-title-bold">
            <div class="body2 sub3 margin-bottom-8">아이디어 테스트 단계</div>
            <div class="size-20 weight-700 main">
              <span>1개월 단위로 플랫폼 서비스를 운영해<br>보고 싶은 창업자를 위한 </span><span class="primary">플랫폼파이<br> 무료 체험해보세요!</span>
            </div>
            <div class="body4 sub3 margin-top-12">블럭 단위로 디자인 편집을 할 수 있는 빌더가 포함되어 있어<br />간단히 내 아이디어에 맞게 설정하고 런칭할 수 있어요.
            </div>
          </div>
          <div id="container-1" class="menus section">
              <card-product style="margin-top:20px" device="mobile" isTheme v-for="theme in themes"
                :key="`theme-${theme.id}`" :card="theme"></card-product>
          </div>
        </div>
        <div class="margin-top-50 mo-platformfy-info-container">
            <div style="padding:0 16px">
              <div class="size-20 main" style="line-height: 32px">이미 많은 고객분들께서 플랫폼파이로</div>
              <div class="body0-bold main" style="line-height: 32px">초기 아이디어를 검증하고,<br />투자를 유치하고 계십니다!</div>
            </div>
            <div style="margin: 28px 0; overflow: hidden">
              <img src="/static/img/home/info_img_full.png" style="height: 220px">
            </div>
            <div class="flex-justify">
              <a href="https://platformfy.com/theme_market" target="_blank">
                <button class="button is-main body2-medium flex-align"
                  style="background-color: transparent; gap:10px;width:280px;height:48px">
                  테마 더보기 <svg-icon icon="u_arrow-up-right" :width="18" :height="18" color="main"></svg-icon>
                </button>
              </a>
            </div>
        </div>
      </div>

      <div class="section-7">
        <div class="container">
          <div class="body0-medium main margin-bottom-24 keep-all">그 외에도 다양한 주문 서비스가 가능합니다!</div>
          <div class="grid"
            style="grid-template-columns: repeat(2, 1fr);grid-row-gap:24px;grid-column-gap:12px;width:100%">
            <div class="position-relative" v-for="(item, idx) in extras" :key="'card-extra-' + idx"
              @click="clickExtra(item)">
              <div class="col-12">
                <div class="card-extra-mo">
                  <div class="flex-justify">
                    <div class="flex-center">
                      <img :src="`/static/img/home/${item.icon}.svg`">
                    </div>
                  </div>
                  <div style="margin-top:14px" class="text-center">
                    <div class="main body5-medium">
                      {{ item.title }}</div>
                  </div>
                </div>
                <div class="body5 text-center sub3" style="word-break:keep-all;padding:0 12px;margin-top:8px">{{ item.desc
                }}</div>
              </div>
              <div class="body7 position-absolute sub-style" style="padding:2px 8px 4px"
                :class="[item.subTitle === 'Prime' ? 'sub-prime' : 'sub-ex']">{{ item.subTitle }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-9">
        <div class="container" style="display: block">
          <div class="body0-medium main margin-bottom-12 keep-all">플랫폼파이 솔루션은 일반적으로 많이 사용되는 기술스펙으로 구성되어 있습니다.</div>
          <div class="body4 sub3 margin-bottom-28">언제든 고도화가 가능하고 투자유치나 사업규모 확장으로 독립이 필요한 경우 별도 계약을 통해
            프론트엔드 소스코드 공유 및 백엔드 API 전송이 가능합니다.</div>
          <img src="/static/img/home/mo-img_tech.png">
        </div>
      </div>

      <div class="section-8">
        <div class="container">
          <div class="body0-medium main margin-bottom-28">전체 플랫폼 테마의<br>기본 화면과 기능들이예요.</div>
          <div class="col-12 flex-justify">
            <slick ref="slick" :options="slickOptionsFuncMobile" style="width:100%">
              <img v-for="(i, idx) in Array(39).fill().map((i, c) => (c % 13) + 1)" :key="'platformfy-func-' + idx"
                :src="`/static/img/home/img_func${i}.png`" style="padding:0 6px">
            </slick>
          </div>
        </div>
      </div>

      <div class="section-8">
        <div class="container">
          <div class="col-12 margin-bottom-8 main body0-medium">만약 필요한 기능이나<br>화면이 있으면 어떻게 하죠?</div>
          <div class="col-12 margin-bottom-24 body4 sub2">앱마켓에서 팩, 플러그인 추가, 템플릿 무제한 이용권 구매, 디자인 요청이 가능해요.</div>
          <img src="/static/img/home/app-market.png">

          <div class="body0-medium main" style="margin:32px 0 12px">원하는 디자인이나 기능이 없다면<br>직접 요청하세요!</div>
          <div class="margin-bottom-24 sub2 body4">내 서비스를 편집하는 빌더에서 간단하게 직접 디자인을 요청하세요!<br>
            일일이 프리랜서와 디자이너에게 컨택을 하고, 명세서를 전달하고, 견적을 받고, 검수할 필요 없이 플랫폼파이에서 올인원으로 필요한 리소스를 해결해 드립니다.</div>
          <div class="flex-justify col-12 margin-bottom-52">
            <img src="/static/img/home/request-frame-mo.png">
          </div>
          <div class="flex-justify col-12">
            <button class="button is-primary-light body2-bold" style="width:200px;height:52px"
              @click="routerPush('/appmarket_info')">앱마켓 자세히 보기</button>
          </div>
        </div>
      </div>


      <div class="section-4">
        <div class="container">
          <div class="margin-bottom-12 main mo-section-title-bold"><span class="mo-section-title">내 서비스 런칭 준비를
              마치셨다면</span><br>앱 포팅 서비스로<br>내 앱을 구글플레이, 앱스토어에<br>올려보세요!</div>
          <div class="col-12 margin-bottom-24 sub2 body4">앱 포팅 및 앱 마켓 등록 대행부터 GA(Google Analytics) 등록 등<br>
            비즈니스 운영에 필요한 부가서비스들을 활용해 내 서비스를 본격적으로 사업화할 수 있습니다.</div>
          <div style="display: grid;grid-template-columns: repeat(3, 1fr);grid-column-gap:8px;grid-row-gap:24px">
            <div v-for="(item, idx) in serviceList" :key="'serv-' + idx">
              <img :src="`/static/img/home/${item.img}.svg`">
              <div style="margin-top:4px" class="body6 main">{{ item.title }}</div>
            </div>
          </div>
          <!-- <div class="flex-justify col-12" style="margin-top:44px">
            <div class="unselect button-border-primary body2-bold"
              @click="clickPush('/market/list?id=49')">서비스 자세히 보기</div>
          </div> -->
        </div>
      </div>

      <div class="section-3">
        <div class="container">
          <div class="mo-section-title-bold main margin-bottom-36"><span class="mo-section-title">이미 많은 고객분들께서
              플랫폼파이로</span><br>초기 아이디어를 검증하고, 투자를 유치하고 계십니다!</div>
          <div class="col-12 margin-bottom-36 main body4-bold text-center">Platformfy로 제작</div>
          <div class="col-12 flex-justify">
            <slick ref="slick" :options="slickOptionsMobile" style="width:100%">
              <img v-for="(i, idx) in Array(15).fill().map((i, c) => (c % 5) + 1)" :key="'platformfy-m-ex-' + idx"
                :src="`/static/img/home/img_platformfy_ex${i}.png`">
            </slick>
          </div>
        </div>
      </div>

      <div class="section-11">
        <img class="position-absolute" src="/static/img/home/ic_ellipse_91.svg"
          style="bottom: -36px;left: calc(50% - 203px);">
        <div class="position-relative" style="z-index:1">
          <img class="margin-bottom-12" style="width:116px;height:28px" src="/static/img/img_logo_launchpack.png"
            alt="런치팩 로고">
          <div class="mo-section-title main margin-bottom-40">
            원하시는 테마가 없으신가요?<br>
            <span class="mo-section-title-bold">
              부분 수정할 수 있는 스킨과<br>맞춤제작이 가능한 제작스토어가<br>있습니다
            </span>
          </div>
          <div>
            <!--<a href="https://pfa.launchpack.co.kr/home" target="_blank">-->
            <a href="https://launchpack.co.kr/home" target="_blank">
              <button class="button is-primary body2-medium" style="width:220px;height:48px">제작스토어 가기</button>
            </a>
          </div>
          <div>
            <img src="/static/img/home/img_skin_market.png" alt="" style="width:300px;height:270px;margin-top:40px">
          </div>
        </div>
      </div>
    </div>

    <popup-notice></popup-notice>
  </div>
</template>

<script>
import PopupNotice from "../modal/PopupNotice";
import Slick from 'vue-slick';
import SvgIcon from "../component/SvgIcon";
import CardProduct from "../component/CardProduct";

export default {
  name: "Home",
  components: {
    SvgIcon,
    PopupNotice,
    Slick,
    CardProduct
  },
  created() {
    if (this.$route.query.recm) {
      this.$store.commit('setRecmCode', this.$route.query.recm);
    }
    this.getProduct();
    if (this.isMobile) {
      this.readyList.push({
        title: '공지사항 팝업',
        icon: 'u_comment-alt-exclamation_color'
      });
    }
  },
  data() {
    return {
      themes: [],
      slickOptions: {
        centerMode: false,
        variableWidth: false,
        infinite: true,
        autoplay: true,
        centerPadding: '20px',
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 300,
        prevArrow: '<div class="arrow-left"><img class="arrow-img" src="/static/icon/fi_chevron-left.svg" @click="$refs.slick.prev()"></div>',
        nextArrow: '<div class="arrow-right"><img class="arrow-img" src="/static/icon/fi_chevron-right.svg" @click="$refs.slick.next()"></div>'
      },
      slickOptionsFunc: {
        centerMode: false,
        variableWidth: false,
        infinite: true,
        autoplay: true,
        centerPadding: '20px',
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 300,
        prevArrow: '<div class="arrow-left"><img class="arrow-img" src="/static/icon/fi_chevron-left.svg" @click="$refs.slick.prev()"></div>',
        nextArrow: '<div class="arrow-right"><img class="arrow-img" src="/static/icon/fi_chevron-right.svg" @click="$refs.slick.next()"></div>'
      },
      slickOptionsMobile: {
        centerMode: false,
        variableWidth: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        centerPadding: '12px',
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 300,
      },
      slickOptionsFuncMobile: {
        centerMode: false,
        variableWidth: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        centerPadding: '12px',
        slidesToShow: 2,
        slidesToScroll: 1,
        speed: 300,
      },
      steps: [
        {
          title: '사업 모델을 구상하고',
          content: '고객이 원하는 플랫폼의 비즈니스\n모델과 필요한 기능을 구상합니다.',
          icon: 'u_lightbulb-alt'
        },
        {
          title: '원하는 디자인과 기능을 조합해서',
          content: '구상한 비즈니스 모델에 맞는 \n블럭(디자인+기능)을 조합합니다.',
          icon: 'u_web-grid-alt'
        },
        {
          title: '결제하면',
          content: '필요한 부가서비스와 대금 지불 방법을\n선택하고 결제합니다.',
          icon: 'u_credit-card'
        },
        {
          title: '내 서비스 개설 완료!',
          content: '서비스 개설이 완료됩니다. 서비스는 다운로드 후 바로 사용이 가능합니다. ',
          icon: 'fi_check-circle_outline'
        },
      ],
      readyList: [
        {
          title: '입찰견적 신청',
          icon: 'u_file-alt'
        },
        {
          title: '쿠폰 / 적립금',
          icon: 'fi_tag'
        },
        {
          title: '본인인증',
          icon: 'u_user-check_color'
        },
        {
          title: '게시판',
          icon: 'u_clipboard-alt_color'
        },
        {
          title: '회원 등급 관리',
          icon: 'u_brightness-plus'
        },
        {
          title: '인스타 피드 연결',
          icon: 'u_instagram'
        },
        {
          title: '파트너 채팅',
          icon: 'u_comment-lines'
        },
        {
          title: '상품 DB\n엑셀 임포팅',
          icon: 'u_file-upload-alt'
        }
      ],
      funcList: [
        {
          title: '대쉬보드',
          icon: 'u_panel-add'
        },
        {
          title: '전화연결',
          icon: 'u_phone-volume'
        },
        {
          title: '고객센터',
          icon: 'u_comment-dots'
        },
        {
          title: '서비스 관리',
          icon: 'u_setting'
        },
        {
          title: '필터/정렬',
          icon: 'u_filter'
        },
        {
          title: '메일/SNS 전송',
          icon: 'u_fast-mail'
        },
        {
          title: '게시판 관리',
          icon: 'u_clipboard-notes'
        },
        {
          title: '정산관리',
          icon: 'u_money-withdraw'
        },
        {
          title: '상품 관리',
          icon: 'u_box'
        },
        {
          title: '회원 관리',
          icon: 'u_user'
        },
        {
          title: '카테고리',
          icon: 'u_apps'
        },
        {
          title: '후기/문의',
          icon: 'u_edit-alt'
        },
        {
          title: '파트너 관리',
          icon: 'u_users-alt'
        },
        {
          title: '예약 관리',
          icon: 'u_calender'
        },
        {
          title: '주문관리',
          icon: 'u_file-check'
        }
      ],
      extras: [
        {
          subTitle: 'Prime',
          title: '견적요청 및 견적서 발송 팩',
          icon: 'color_file-alt',
          desc: '#이사 견적 플랫폼',
        },
        {
          subTitle: 'Platformfy',
          title: '클릭 시 링크이동 팩',
          icon: 'color_external-link-alt',
          desc: '# 상품을 가격 비교 후 사이트 이동 플랫폼',
        },
        {
          subTitle: 'Prime',
          title: '로컬배달 주문 팩',
          icon: 'draw_motorcycle',
          desc: '# 음식점 배달 플랫폼',
        },
        {
          subTitle: 'Prime',
          title: '자료컨텐츠 다운로드 팩',
          icon: 'color_download-alt',
          desc: '# 온라인 자료실 플랫폼',
        },
        {
          subTitle: 'Platformfy',
          title: '커뮤니티 팩',
          icon: 'color_megaphone',
          desc: '# 온라인 커뮤니티 플랫폼',
        },
        {
          subTitle: 'Platformfy',
          title: '상담신청 팩',
          icon: 'color_comment-dots',
          desc: '# 병원 상담 플랫폼',
        },
      ],
      serviceList: [
        {
          title: '콘텐츠 스토리지',
          img: 'content_storage'
        },
        {
          title: '페이플 PG',
          img: 'payple'
        },
        {
          title: '카카오 페이 간편결제',
          img: 'kakao_pay'
        },
        {
          title: '네이버 페이 간편결제',
          img: 'naver_pay'
        },
        {
          title: 'GA(Google Analytics)',
          img: 'img_GA'
        },
        {
          title: '도메인',
          img: 'img_domain'
        },
        {
          title: '앱포팅',
          img: 'img_porting'
        }
      ],
      priceInfo: [
        {
          type: 'head',
          plan: '플랜',
          signup: '회원가입 수',
          prodReg: '상품 등록 수',
          prodCat: '상품 카테고리 수',
          partReg: '파트너 등록 수',
          admin: '관리자 계정 갯수',
          ticket: '블럭 템플릿 무제한 이용권',
          device: '모바일/PC'
        },
        {
          title: 'Basic',
          desc1: '최소 스펙으로',
          desc2: '나만의 MVP를 구현해 보고 싶을 때',
          btn: '14일 무료체험',
          link: '/theme_market',
          org_price: 140000,
          plan: {
            one: {
              title: '1개월',
            },
            three: {
              title: '3개월',
              discount_rate: 10
            },
            six: {
              title: '6개월',
              discount_rate: 30
            },
            twelve: {
              title: '12개월',
              discount_rate: 50
            },
          },
          signup: '500명',
          prodReg: '100건',
          prodCat: '10개',
          partReg: '20건',
          admin: '1명',
          ticket: '베타버전 무료제공',
          device: '모바일'
        },
        {
          title: 'Basic PC+',
          desc1: 'MVP 서비스를 모바일 뿐만 아니라',
          desc2: 'PC웹 서비스까지',
          btn: '14일 무료체험',
          link: '/theme_market',
          org_price: 240000,
          plan: {
            one: {
              title: '1개월',
            },
            three: {
              title: '3개월',
              discount_rate: 10
            },
            six: {
              title: '6개월',
              discount_rate: 30
            },
            twelve: {
              title: '12개월',
              discount_rate: 50
            },
          },
          signup: '500명',
          prodReg: '100건',
          prodCat: '10개',
          partReg: '20건',
          admin: '1명',
          ticket: '베타버전 무료제공',
          device: '모바일/PC'
        },
        {
          title: 'Start-up',
          desc1: '씨드투자 유치 전',
          desc2: '본격적인 회원/파트너 등록이 필요할 때',
          btn: '14일 무료체험',
          link: '/theme_market',
          org_price: 280000,
          plan: {
            one: {
              title: '1개월',
            },
            three: {
              title: '3개월',
              discount_rate: 10
            },
            six: {
              title: '6개월',
              discount_rate: 30
            },
            twelve: {
              title: '12개월',
              discount_rate: 50
            },
          },
          signup: '10000명',
          prodReg: '3000건',
          prodCat: '20개',
          partReg: '100건',
          admin: '5명',
          ticket: '베타버전 무료제공',
          device: '모바일/PC'
        },
        {
          title: 'Prime',
          desc1: '나에게 꼭 맞춘',
          desc2: '커스텀 플랫폼',
          btn: '제작 상담 신청',
          link: '/prime?form=true',
          plan: '상담 후 진행\n단계별 견적 안내',
          signup: '무제한',
          prodReg: '무제한',
          prodCat: '무제한',
          partReg: '무제한',
          admin: '무제한',
          ticket: '베타버전 무료제공',
          device: '모바일/PC'
        }
      ],
      popupMarket: undefined,
      products: {
        call: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0
        },
        reservation: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0
        },
        booking: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0
        },
        delivery: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0
        },
        estimate: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0
        },
      }
    }
  },
  methods: {
    clickInquiryCustom() {
      const a = document.createElement("a");
      a.href = 'https://launchpack.co.kr/prime';
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    },
    getProduct() {
      this.$axios.get('user/0/mapping/product', {
        params: {
          category: 59,
          fields: 'id,name,simple_desc,price,img,partner.img,partner.name,category1,category2,preview_url,rate,visit,params,tags,theme_version',
          ordering: 'priority'
        }
      }).then(res => {
        this.themes = res.data.data;
        res.data.data.forEach(i => {
          let key = i.params.find(p => p.name === 'key').value;
          let obj = this.products[key];
          obj.id = i.id;
          obj.sale_price = i.price.sale_price;
          obj.discount_rate = i.price.discount_rate;
          obj.rate = i.rate;
          obj.visit = i.visit;
          obj.partner = i.partner;
          obj.img = i.img;
          obj.name = i.name;
          obj.tags = i.tags;
        });
      });
    },
    productDetail(id) {
      let width = 1231;
      let height = 760;
      let url = `market/detail?id=${id}`;
      this.popupMarket = window.open(url, 'appMarket', `width=${width}, height=${height}, top=0, left=0, menubar=no, toolbar=no, titlebar=yes`);
      this.addMessageEvent();
    },
    addMessageEvent() {
      window.addEventListener('message', this.onMessageMarket);
    },
    removeMessageEvent() {
      window.removeEventListener('message', this.onMessageMarket);
    },
    onMessageMarket(e) {
      if (e.data.close) {
        this.popupMarket.close();
        this.removeMessageEvent();
        if (e.data.redirectUrl) {
          setTimeout(() => {
            window.open(e.data.redirectUrl);
          }, 300);
        }
      }
    },
    clickPush(path) {
      let width = 1231;
      let height = 760;
      this.popupMarket = window.open(path, 'appMarket', `width=${width}, height=${height}, top=0, left=0, menubar=no, toolbar=no, titlebar=yes`);
      this.addMessageEvent();
    },
    optPlan(name) {
      let word = name.replace('\n', '<br>')
      return `<div class="col-12 body3 sub2 text-center">${word}</div>`;
    },
    isPrime(item) {
      if (item.subTitle === 'Prime') {
        return true
      }
    },
    clickPreview(url) {
      /*window.name = 'preview_parent';
      let popup = window.open('', 'previewPopup', 'width=360, height=640, top=30, left=30, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=yes, scrollbar=no');
      popup.location.href = url;*/
      const a = document.createElement("a");
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    },

    icon(name) {
      return `/static/icon/${name}.svg`;
    },
    img(name) {
      return `/static/img/home/${name}.png`;
    },
    clickExtra(item) {
      if (item.link) {
        this.routerPush(item.link);
      }
    }
  }
}
</script>


<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  @keyframes img-slider
    from
      transform translateX(0)
    to
      transform translateX(-100%)

  .img-slider
    width 677px
    overflow-x hidden
    .img
      animation img-slider 1200s linear infinite
      display flex
      width 53100px
    img
      height 280px
    .img:hover
      animation-play-state paused

  table
    width 100%
  th
    border 1px solid $gray1
  td
    padding 16px
    text-align center
    border 1px solid $gray1

  .container
    height 100%
    display flex
    align-items center
    flex-wrap wrap
    max-width 1152px !important

  .bg-container
    height 100%
    display flex
    align-items center
    justify-content center
    flex-wrap wrap
    max-width 1152px !important
    padding-left 30px

  .platformfy-info-container
    padding 60px 0 100px
    display flex
    align-items center
    gap 0 80px
  .section
    padding-bottom 50px

  .mo-platformfy-info-container
    background linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #EFEFEF 100%)
    padding-bottom 50px

  .section-1
    background url(/static/img/home/background_main_banner.png)
    background-repeat no-repeat
    background-position center
    background-size cover
    padding 64px 0 60px 0

  .section-1-mo
    background url(/static/img/home/background_main_banner_mo.png)
    background-repeat no-repeat
    background-position center
    background-size cover
    height 800px
    overflow hidden

  .section-2
    height 656px

  .section-3
    padding 100px 0 124px 0
    background-color $gray4

  .section-4
    padding 112px 0

  .section-5
    padding 100px 0

  .section-6
    padding 100px 0 150px 0
    background url(/static/img/home/background_img_plugin.png)
    background-size cover
    background-position center
    background-repeat no-repeat

  .section-7
    padding 150px 0
    background-color $gray4

  .section-8
    padding 100px 0 50px 0

  .section-9
    padding 80px 0

  .section-10
    background-color black
    padding 120px 0

  .section1-img
    width 290px
    height 290px

  .button-home
    border-radius 8px
    padding 16px 24px
    text-align left

  .button-home-circle
    border-radius 100%
    padding 38px 16px
    width 124px
    height 124px
    text-align left

  .button-home:hover
  .button-home-circle:hover
    box-shadow 0 10px 20px rgba(255, 102, 0, 0.15)

  .button-primary
  .button-home-circle
    border 0
    background linear-gradient(322.3deg, #FF6600 22.51%, #F43B00 100%)
    color white

  .button-primary-light
    background-color white
    color $primary
    border 1px solid $primary

  .button.is-dark
  .button.is-gray
    width 130px
    height 40px

  .button-black
    background-color black
    color white

  .bg-prime
    background linear-gradient(132.62deg, #242428 34.49%, #4F4F4F 88.8%)
    width 100%
    height 300px
    overflow hidden

  .bg-prime-mo
    background linear-gradient(132.62deg, #242428 34.49%, #4F4F4F 88.8%)
    width 100%
    height 140px
    overflow hidden
  .sec2-box
    background-color $gray4
    padding 20px 24px
    display flex
    align-items center
    width 466px
    border-radius 12px

  .step-icon
    width 80px
    height 80px
    background-color white
    border-radius 20px
    box-shadow 0 20px 40px rgba(0, 0, 0, 0.04)
    display flex
    justify-content center
    align-items center

  .dot-line
    flex 1
    overflow hidden
    margin-left 32px

  .dot
    width 4px
    height 4px
    background-color $primary
    margin-right 8px
    display inline-block
    border-radius 100%

  .card-product
    flex 1
    height 320px
    display flex
    justify-content space-between
    border-radius 12px
    align-items center
    overflow hidden
  .card-product:hover
    filter drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.15))

  .design-market
    background-color #7B61FF
    margin-right 24px

  .order
    background linear-gradient(134.45deg, #00BF91 41.03%, #17FF90 105.39%)
    margin-left 24px

  .card-content
    color white
    margin-left 40px
    flex 1

  .ready-item
    text-align center
    background-color white
    border 1px solid $gray1
    box-shadow 0 20px 20px rgba(0, 0, 0, 0.04)
    border-radius 12px
    height 160px
    display flex
    align-items center
    justify-content center

  .card-service
    padding 50px 40px
    background-color white
    border 1px solid $gray1
    border-radius 12px
    box-shadow 0 20px 20px rgba(0, 0, 0, 0.04)
    display flex
    flex-direction column
    justify-content space-between

  .card-divider
    width 100%
    height 1px
    background-color $gray3
    margin 28px 0

  .card-service-price
    border-top 1px solid $gray3
    padding-top 28px
    margin-top 28px

  .card-extra
    background-color white
    border-radius 8px
    padding 36px 0 20px 0
    border 1px solid $gray2

  .card-extra-icon-bg
    background-color white
    border-radius 12px
    width 60px
    height 60px
    text-align center
    line-height 60px
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.04)

  .card-extra-mo
    background-color white
    border-radius 8px
    padding 40px 10px 24px
    border 1px solid $gray2

  .button-border-primary
    color $primary
    padding 13px 36px
    text-align center
    border 1px solid $gray
    border-radius 8px


  .box-primary-light2
    background-color $primary-light2
    color $primary
    gap 12px
    display flex
    align-items center
    justify-content center
    padding 32px 12px
    border-radius 8px

    .emoticon
      width 40px
      height 40px

  .grid-product-wrap
    display grid
    width 100%
    grid-template-columns repeat(4, 1fr)
    gap 24px

  @media (min-width:1025px)
    .section-11
      position relative
      background-color #FFF9F9
      height 610px
      display flex
      align-items center
      justify-content center
      overflow hidden

  // tablet
  @media (max-width:1024px)
    .pc
      display none
    .tablet
      display block
    .mobile
      display block

    .button-home
      padding 10px 12px
      width 140px

    .section-2
    .section-3
    .section-4
    .section-5
    .section-6
    .section-7
    .section-8
    .section-9
      padding 60px 0
      height initial

    .section-11
      position relative
      background-color #FFF9F9
      padding 60px 0
      text-align center
      overflow hidden

    .sec2-box
      width 488px
      margin-top 12px

    .step-icon
      margin-right 20px

    .ready-item
      height 88px

    .card-product-mobile
      flex 1
      border-radius 8px
      overflow hidden
      filter drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.15))
      display flex
      flex-direction column
      height 180px

    .card-content
      margin 20px
      flex 1

    .design-market
      margin-right 0
      background-image url(/static/img/home/mockup_design_market_mo.png)
      background-size contain
      background-position right
      background-repeat no-repeat
    .order
      margin-left 0

    .order-background-image
      background-image url(/static/img/home/img_order_make_mo.png)
      background-size contain
      background-position right
      background-repeat no-repeat
      height 100%

  @media (max-width:640px)
    .pc
      display none
    .tablet
      display none
    .mobile
      display block
    .container
      flex-direction column
      flex-wrap nowrap
      align-items flex-start



    .section-1
      background url(/static/img/home/background_main_banner_mo.png)
      background-repeat no-repeat
      background-position center
      background-size cover
      height calc(100vh - 60px)
      position relative

    .section-6
      background url(/static/img/home/background_mo.png)
      background-size cover
      background-position center

    .section-2
    .section-3
    .section-4
    .section-5
    .section-6
    .section-7
    .section-8
    .section-9
    .section-11
      padding 60px 0
      height initial

    .section-3
    .section-7
      background-color $gray4

    .sec2-box
      width 100%
      padding 18px 12px

    .circle1
      top -113px
      right -10px
      left initial
      width 241px
      height 241px

    .circle2
      left -115px
      top 294px
      width 274px
      height 274px
      background #7B61FF
      opacity 0.3
      filter blur(320px)
      -webkit-backdrop-filter blur(320px)
      backdrop-filter blur(320px)

    .circle3
      width 215px
      height 215px
      left 244px
      top 353px
      background #17FF90
      opacity 0.1
      filter blur(130px)
      -webkit-backdrop-filter blur(130px)
      backdrop-filter blur(130px)

    .selection1-img
      position static
      transform initial
      width 328px
      margin-right 56px

    .button-home
      padding 10px 12px
      width 100%

    .card-content
      margin 20px
      flex 1

    .step-icon
      width 48px
      height 48px
      margin-right 20px
      border-radius 12px

    .card-product-mobile
      flex 1
      border-radius 8px
      overflow hidden
      filter drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.15))
      display flex
      flex-direction column
      height auto

    .design-market
      margin-right 8px
    .order
      margin-left 8px

    .bounce
      animation-iteration-count 10
      -webkit-animation-duration 1s
      animation-duration 1s
      -webkit-animation-name bounce
      animation-name bounce

    .ready-item
      border-radius 4px
      box-shadow none
      padding 12px 8px
      height initial

    .card-service
      box-shadow 0 6px 10px rgba(0, 0, 0, 0.05)
      margin-bottom 20px
      padding 20px
    .card-service:last-child
      margin-bottom 0

    .card-divider
      margin 12px 0

    .card-service-price
      margin-top 12px
      padding-top 12px

    .card-extra
      width 100%
      border 1px solid $gray1
      padding 20px
      border-radius 8px
      margin-bottom 12px

    .card-extra-icon-bg
      width 48px
      height 48px
      line-height 60px

    @-webkit-keyframes bounce {
      0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
      40% {-webkit-transform: translateY(-8px) }
      60% {-webkit-transform: translateY(-4px);}
    }
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
      40% {transform: translateY(-8px);}
      60% {transform: translateY(-4px);}
    }

    .design-market
      background-image initial
    .order-background-image
      background-image initial

  .partner-box
    background-color white
    border-radius 12px
    height 132px
    padding 20px 24px

  .contain
    position absolute
    left 0
    top 70%

  .sec3-box
    background-color #EEEEEE
    border-radius 12px
    padding 52px 66px
    width 100%

  .sec3-box-mo
    background-color #EEEEEE
    border-radius 12px
    padding 24px 20px 60px
    height 298px
    width 100%

  .sec3-txt1
    background-color #AEAEAE
    border-radius 50px
    padding 10px 16px
    color white
    width 350px

  .sec3-txt1-mo
    background-color #AEAEAE
    border-radius 50px
    padding 6px 10px
    color white
    width 294px

  .sec3-txt2
    background-color $primary
    border-radius 50px
    padding 10px 16px
    color white

  .sec3-txt2-mo
    background-color $primary
    border-radius 50px
    padding 6px
    color white
    width 100%

  .sec3-box2
    background-color white
    padding 52px 66px
    width 100%
    border-radius 12px
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04)

  .sec3-box2-mo
    background-color white
    padding 24px 20px 40px
    width 100%
    border-radius 12px
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04)

  .func-item
    padding 16px
    background-color white

  .func-item-mo
    padding 9px 16px
    background-color white

  .base-platform
    flex 1
    background-color white
    padding 40px 52px

  .label-btn
    background-color $primary-light2
    padding 2px 8px
    border-radius 4px
    width 68px

  .extras-icon-bg
    width 60px
    height 60px
    background-color white

  .sub-style
    top 8px
    left 8px
    border-radius 50px
    padding 3px 8px 4px

  .sub-prime
    color $primary
    background-color black

  .sub-ex
    color white
    background-color $primary

  .desc-content
    background-color $gray3
    width 342px
    height 368px
    border-radius 20px
    padding 24px

  .desc-content-title
    height 112px
    padding-bottom 16px
    border-bottom 1px solid #DDDDDD
    flex-direction column

  .desc-content-box
    padding 16px 12px
    background-color $gray3
    border-radius 8px
    text-align center

  .button-prime
    padding 16px 0
    text-align center
    width 100%
    border-radius 8px

  .price-btn
    width 210px
    border-radius 8px
    padding 8px 0

  .price-btn-bk
    background-color black
    color white
    border none

  .price-prime
    color black
    border-top 3px solid black

  .price-head
    color $primary
    border-top 3px solid $primary

  .td-exp
    background-color $primary-light2
    padding 6px
    border-bottom 0

  .th-empty
    border-top none
    border-left none

  .button-home-mo
    width 172px
    padding 12px
    border-radius 8px

  .pack-item
    padding 16px 0 36px
    border-bottom 1px solid $gray1

  .price-item-box
    border-top 3px solid $primary
    border-left 1px solid $gray1
    border-right 1px solid $gray1
    border-bottom 1px solid $gray1
    padding 16px 16px 22px
    margin-bottom 24px
    width 100%

  .item-title
    padding-bottom 16px
    border-bottom 1px solid $gray1

  .item-title2
    padding 12px 0
    border-bottom 1px solid $gray1

  .line-ver
    width 1px
    height 15px
    background-color $primary

  .platform-box
    overflow hidden
    padding 100px 0
    border-bottom 1px solid $gray2

  .prime-bg
    background url(/static/img/home/img_prime_back.png)
    background-repeat no-repeat
    background-position center
    background-size contain
    height 380px

  .prime-bg-mo
    background url(/static/img/home/img_prime_back.png)
    background-repeat no-repeat
    background-position center
    background-size contain
    height 260px

  .arrow-box
    padding 60px 0
    border-top 1px solid $sub2
    border-bottom 1px solid $sub2

  .dot-prime
    width 29px
    height 29px
    background-color white
    border-radius 50%

  .prime-item1
    border-bottom 1px dashed $primary-dark
    padding 14px 16px 14px 0

  .prime-item2
    border-bottom 1px solid $primary-dark
    padding-right 16px

  .prime-item-arrow
    border-bottom 1px dashed $primary
    padding-right 16px

  .dot-prime-mo
    width 22px
    height 22px
    background-color white
    border-radius 50%

  .rec-prime
    width 207px
    height 26px
    background-color $sub2

  .rec-prime-mo
    width 14px
    height 44px
    background-color $sub2

  .box-primary-light2-mo
    background-color $primary-light2
    color $primary
    padding 12px 0 20px 0
    border-radius 8px
    display block

    .emoticon
      width 36px
      height 36px

  .tags
    margin-top 12px
    display flex
    align-items center
    gap 6px
    .tag
      border 1px solid $gray
      padding 2px 8px
      border-radius 50px
      font-size 11px
      color $sub
</style>
